import { useRoute } from 'nuxt/app';

export default function useCurrentCountry(): { currentCountry: string } {
  const route = useRoute();
  const currentCountry = route.params.country as string;

  return {
    currentCountry,
  };
}
